import { Box, Link, Rating, Typography } from '@hermes/web-components'
import NextImage from 'next/image'
import dayjs from 'dayjs'
import { animated, useInView } from '@react-spring/web'
import { StrapiMedia } from '../../../../types/strapi'
import { ReviewContent, ReviewerWrapper, ReviewTitle, TrustedByReviewWrapper } from './styles'

export interface TrustedByReviewProps {
  id: number
  reviewRating: number
  createDate: string
  review: string
  reviewerName: string
  reviewUrl?: string
  reviewerAvatar: {
    data: StrapiMedia
  }
  companyName: string
  reviewedOnClutch?: boolean
}

const TrustedByReview = ({
  reviewRating,
  companyName,
  createDate,
  reviewerAvatar,
  review,
  reviewerName,
  reviewedOnClutch,
  reviewUrl
}: TrustedByReviewProps) => {
  const [reviewCardAnimationRef, reviewCardAnimationSprings] = useInView(() => ({
    from: {
      opacity: 0,
      transform: 'translateY(100px)'
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  }))

  const onOpenReview = () => {
    window.open(reviewUrl, '_blank')
  }

  const AnimatedReviewCard = animated(TrustedByReviewWrapper)

  return (
    <AnimatedReviewCard ref={reviewCardAnimationRef} style={reviewCardAnimationSprings}>
      <div>
        <ReviewTitle>
          {reviewedOnClutch ? (
            <svg width="80" height="43">
              <use href="#reviewed-on-clutch" />
            </svg>
          ) : (
            <Typography>{dayjs(createDate).format('DD MMM. YYYY')}</Typography>
          )}
          <Rating
            value={reviewRating}
            sx={{
              paddingTop: '27px',
              svg: {
                fill: '#E52314'
              }
            }}
            max={5}
            readOnly
          />
        </ReviewTitle>
        <ReviewContent
          {...(reviewUrl
            ? {
                onClick: onOpenReview,
                sx: {
                  cursor: 'pointer'
                }
              }
            : {})}
        >
          {review}
        </ReviewContent>
      </div>
      <ReviewerWrapper>
        <NextImage src={reviewerAvatar.data?.attributes.url} width={50} height={50} alt={companyName} />
        <Box>
          <Typography>{reviewerName}</Typography>
          <Typography
            component={Link}
            href={reviewUrl}
            target="_blank"
            sx={{
              display: 'inline-block',
              textDecoration: 'none',
              color: 'typography.primary'
            }}
          >
            {companyName}
          </Typography>
        </Box>
      </ReviewerWrapper>
    </AnimatedReviewCard>
  )
}

export default TrustedByReview
